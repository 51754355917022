
import { Options, Vue } from 'vue-class-component';
import { EstimationClient, FootwearEstimationClient, ProviderClient } from '@/services/Services';
import { baseUrl } from '@/config';
import BestFootwearCombinationTable from '../components/bestFootwearCombinationTable.vue';
import SuggestionFiltersModal from '../modals/suggestionFiltersModal.vue';
import filters from '@/filters';
import * as OM from '@/Model';

@Options({
    components: {
        BestFootwearCombinationTable
    }
})
export default class BestFootwearCombination extends Vue {

    estimationIdentifier: string = "";
    originalEstimation: OM.BestFootwearCombination = new OM.BestFootwearCombination();
    bestCombinationList: OM.BestFootwearCombination[] = [];
    selectedIndex: number = 0;

    filters: OM.BestCombinationFilters = new OM.BestCombinationFilters();

    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.filters.estimationIdentifier = this.estimationIdentifier;
        this.init();
    }

    init() {
        Promise.all([
            FootwearEstimationClient.getFootwearEstimationData(this.estimationIdentifier),
            EstimationClient.bestFootwearCo2EmissionsConfigurationByGarment(this.filters)
        ])
        .then(xs => {
            this.originalEstimation = xs[0];
            this.bestCombinationList = xs[1];
        })
    }

    get bestCombination() {
        return this.bestCombinationList[this.selectedIndex];
    }

    openFilters() {
        this.$opModal.show(SuggestionFiltersModal, {
            garmentIdentifier: this.originalEstimation.bestFootwearEstimation.footwearData.garment.identifier,
            activeFilters: this.filters,
            showGSMSuggestion: false,
            callback: (keepUsingEstimationRawMaterialsString: string, useProvidersString: string, keepUsingEstimationGSMString: string) => {
                this.filters.keepUsingOriginalEstimationRawMaterials = keepUsingEstimationRawMaterialsString == true.toString();
                this.filters.useUserProviders = useProvidersString == true.toString();
                this.filters.useSameGrams = keepUsingEstimationGSMString == true.toString();

                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    downloadBestCombinationPDF() {
        var actualDate = filters.date(new Date(), "dd-MM-YYYY", false);
        var fileName = "best_combination_" + this.bestCombination.bestFootwearEstimation.sku + "_" + actualDate;
        this.$utils.downloadBestCombinationPdf(baseUrl + "api/Estimation/DownloadBestFootwearCombinationPDF", this.bestCombination.identifier, fileName);
    }

}
